import React, { useState } from "react";

import "./style.css"
import "./media.css"

const Join = () => {
    const [copySuccess, setCopySuccess] = useState("");

    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
  
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(
        () => {
          setCopySuccess("Текст скопійовано!");
          setTimeout(() => {
            setCopySuccess("");
          }, 1000);
        },
        (err) => {
          setCopySuccess("Помилка копіювання тексту");
        }
      );
    };
  
    return ( 
        <div className="join">
        <h2 className="join__title title">ПРИЄДНУЙСЯ ДО НАС</h2>
        <div className="online">
          <h2 className="online__title">ЗАРАЗ ГРАЮТЬ</h2>
          <p className="online__value">54</p>
        </div>
        <div className="join__cards">
          <div className="join__card">
            <p className="card__number">1</p>
            <p className="card__title">
              Встановлення ліцензійної копії гри <br className="responsive-br" />
              DayZ
            </p>
            <button
              onClick={() =>
                openInNewTab("https://store.steampowered.com/app/221100/DayZ/")
              }
              className="card__btn steam__btn"
            >
              До STEAM магазину
            </button>
          </div>
          <div className="join__card card2">
            <p className="card__number">2</p>
            <p className="card__title">
              Встановлення та запуск DayZ SA <br class="responsive-br" />
              Launcher
            </p>
            <button
              onClick={() => openInNewTab("https://dayzsalauncher.com/")}
              className="card__btn launch__btn"
            >
              DayZ SA Launcher
            </button>
          </div>
          <div className="join__card card3">
            <p className="card__number">3</p>
            <p className="card__title">
              Пошук серверу у лаунчері за його <br className="responsive-br" />
              назвою
            </p>
            <button
              onClick={() => copyToClipboard("UA S.T.A.L.K.E.R.s Reborn")}
              className="card__btn search__btn"
            >
              UA S.T.A.L.K.E.R.s Reborn
            </button>
            {copySuccess && <div className="copy-success">{copySuccess}</div>}
          </div>
        </div>
      </div>
     );
}
 
export default Join;