import "./style.css";

import discordIcon from "./../../img/discord.png"
import youtubeIcon from "./../../img/youtube.png"

const Footer = () => {
  return (
    <footer>
      <div className="social">
        <a href="https://discord.com/invite/FRajDVuFY6" className="discord__link"><img src={discordIcon} alt="Discord" /></a>
        <a href="https://www.youtube.com/@S.T.A.L.K.E.R.sReborn" className="youtube__link"><img src={youtubeIcon} alt="YouTube" /></a>
      </div>
    </footer>
  );
};

export default Footer;
