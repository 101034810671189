import React, {} from "react";

import Header from "./../../components/header/Header";
import Hero from "./../../components/hero-section/Hero";
import Join from "./../../components/join/Join";
import Blog from "./../../components/blog/Blog";
import Footer from "./../../components/footer/Footer";

const Home = () => {

  return (
    <>
    <Header />
      <Hero />
      <div className="gradient-container fixed_gradient">
        <div className="gradient"></div>
      </div>
      <Join />
      <div className="gradient-container">
        <div className="gradient"></div>
      </div>
      <Blog />
       <div className="gradient-container">
        <div className="gradient"></div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
