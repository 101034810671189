import './style.css'
import './media.css'


const Burger = () => {
  const open = () => {
    const menu = document.getElementById('menu');
    if (menu) {
      menu.style.display = 'block';
    }
  };

  const close = () => {
    const menu = document.getElementById('menu');
    if (menu) {
      menu.style.display = 'none';
    }
  };
    return ( 
      <>
        <a href="#menu" id="toggle" onClick={open}><span></span></a>
        
        <div id="menu">
        <span className='close' onClick={close}>✖</span>
          <ul>
            <li><a href="/#" className='burgerNav' onClick={close}>Головна</a></li>
            <li><a href="#blog" className='burgerNav' onClick={close}>Блог</a></li>
            <li><a href="/#" className='burgerNav' onClick={close}>Магазин</a></li>
          </ul>
        </div>
      </>
     );
}
 
export default Burger;