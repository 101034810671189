import "./style.css";

import { useParams } from "react-router-dom";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useState, useEffect } from "react";

const url = process.env.REACT_APP_BACKEND_URL + "blog/detail/";

const Blog = () => {
  const [blogDetails, setBlogDetails] = useState();

  const params = useParams();
  const blogUrl = url + params.slug;

  function getBlogDetails() {
    fetch(blogUrl)
      .then((response) => response.json())
      .then((data) => {
        setBlogDetails(
          <>
            <h1 className="blog__title">{data.title}</h1>
            <h3 className="blog__description">{data.content}</h3>
            <p className="blog__created_at">{data.created_at}</p>
          </>
        );
      });
  }
  useEffect(() => {
    getBlogDetails();
  }, []);
  return (
    <>
      <Header />
      <div className="gradient-container">
        <div className="gradient"></div>
      </div>
      <div className="container">{blogDetails}</div>
      <div className="gradient-container">
        <div className="gradient"></div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
