import React, { useState, useEffect } from "react";

import "./style.css";
import "./media.css";

import BurgerMenu from './../menuBurger/Burger'

import headerBtn from "./../../img/steam_button_icon.png";

const url = process.env.REACT_APP_BACKEND_URL + "user/details/";
const urlBalance = process.env.REACT_APP_BACKEND_URL + "user/balance/";
const urlLogout = process.env.REACT_APP_BACKEND_URL + "user/logout/";

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = () => {
    setIsVisible((prevState) => !prevState);
  };

  const [userDetails, setUserDetails] = useState();
  const [userBalance, setUserBalance] = useState(0);
  const [userAccount, setUserAccount] = useState(false);

  function updateUserBalance() {
    fetch(urlBalance)
      .then((res) => res.json())
      .then((data) => {
        setUserBalance(data.balance);
      });
  }

  function accountLogout() {
    fetch(urlLogout);
    setUserAccount(false);
  }

  function getUserDetails() {
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((data) => {
        setUserDetails(
          <div className="user__info" onClick={handleClick}>
            <div className="user__text">
              <p className="user__name">{data.nickname}</p>
              <p className="user__balance">
                <span className="light__balance">Баланс -</span> {userBalance}₴
              </p>
            </div>
            <img
              className="user__avatar"
              src={data.avatar_url}
              alt="User Avatar"
            />
          </div>
        );
        setUserAccount(true);
      })
      .catch((error) => {
        setUserDetails(
          <div className="steam__link">
            <a href={process.env.REACT_APP_BACKEND_URL + "user/login/"}>
              <img src={headerBtn} alt="steam icon" />
            </a>
          </div>
        );
      });
  }

  updateUserBalance();
  useEffect(() => {
    getUserDetails();
  }, [userBalance, userAccount]);

  return (
    <header>
      <nav className="header__nav">
        <ul className="header__list">
          <li className="header__item">
            <a href="/#" className="header__link">
              Головна
            </a>
          </li>
          <li className="header__item">
            <a href="#blog" className="header__link">
              Блог
            </a>
          </li>
          <li className="header__item">
            <a href="#" className="header__link">
              Магазин
            </a>
          </li>
        </ul>
      </nav>
       <BurgerMenu />
      {userDetails}
      <div
        className="personal__office"
        style={{ display: isVisible ? "block" : "none" }}
      >
        <button className="personalBtn active">Особистий Кабінет</button>
        <hr />
        <button className="personalBtn">Поповнити баланс</button>
        <button className="personalBtn">Проголосувати за сервер</button>
        <hr />
        <button className="personalBtn" onClick={accountLogout}>Вихід</button>
      </div>
    </header>
  );
};

export default Header;
