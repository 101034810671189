import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import moment from "moment";
import "moment/locale/uk";
import "./style.css";
import "./media.css";

import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

import leftArrow from "./../../img/leftArrow.png";
import rightArrow from "./../../img/rightArrow.png";

const url = process.env.REACT_APP_BACKEND_URL + "blog/list/";

export default function Blog() {
  const [blogs, setBlogs] = useState();

  let displayBlogs;
  function blogList() {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        displayBlogs = data.map(function (blog) {
          moment.locale("ua");
          const blogDate = new Date(blog.created_at);
          const formattedDate = moment(blogDate).format("DD MMMM YYYY");
          const blogUrl = "/blog/" + blog.slug;
          return (
            <SwiperSlide key={blog.slug}>
              <div className="slide-content">
                <a href={blogUrl}>
                  <img
                    className="slide__img"
                    src={blog.image_url}
                    alt="Slide 1"
                  />
                  <div className="slide__text">
                    <p className="slide__title">
                      {blog.title.toUpperCase()} <br />
                      {blog.short_description.toUpperCase()}
                    </p>
                    <p className="slide__date">{formattedDate}</p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          );
        });
        setBlogs(displayBlogs);
      });
  }

  useEffect(() => {
    blogList();
  }, []);

  return (
    <div id="blog" className="blog">
      <h1 className="blog__title title">БЛОГ</h1>
      {/* <!-- -----slider-------> */}
      <div className="swiper-container">
        <Swiper
          spaceBetween={74}
          slidesPerView={1.6}
          breakpoints={{
        320: {
          slidesPerView: 1,
        },
        425: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1.4,
        },
        1920: {
          slidesPerView: 1.6,
        },
      }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      className="swiper-container"
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          loop={true}
          speed={800}
          modules={[Navigation]}
        >
          {blogs}
        </Swiper>
        {/* Custom navigation buttons */}
        <div className="swiper-button-prev swiperButton">
          <img src={leftArrow} alt="Previous" />
        </div>
        <div className="swiper-button-next swiperButton">
          <img src={rightArrow} alt="Next" />
        </div>
      </div>
      {/* <!-- -----slider-------> */}
    </div>
  );
}
