import "./style.css"
import "./media.css"

const Hero = () => {
  return (
    <main id="hero" className="hero-section">
      <h1 className="main__title">
        ЕДЕМ - ВІДЧУЙ ЖИТТЯ
        <br />
        БЕЗ АЛЕГРІЇ
      </h1>
    </main>
  );
};

export default Hero;
