import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./styles/style.css";

import BlogPage from "./pages/blogPage/BlogPage";
import HomePage from "./pages/homePage/HomePage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/blog/:slug" element={<BlogPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
